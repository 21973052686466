/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.model-list::v-deep {
  margin-bottom: 32px;
}
.model-list::v-deep .drone-model-card {
  width: 400px;
}
